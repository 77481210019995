<template>
  <div class="payDonePage">
    <div class="contant">
      <div class="icon"><van-icon name="checked" size="48px" /></div>
      <div class="text">支付成功</div>

      <div class="title">且玩游戏社</div>
      <div class="price"><img src="@/assets/img/rmb_icon3.png" alt=""><span>{{ allprice }}</span></div>
      <div class="tip">我们将在两个工作日内联系您</div>
    </div>

    <div class="bottom">
      <wx-open-launch-weapp id="launch-btn" appid="wxefacd04a437a42a7" :path="linkurl">
        <component :is="'script'" type="text/wxtag-template">
          <div class="btn">
            <button type="button">查看订单</button>
          </div>
        </component>
      </wx-open-launch-weapp>
      <!-- <div class="btn">
        <button type="button" @click="toOrder">查看订单</button>
      </div> -->
      <div class="backhome" @click="flowCkick">关注MetaDeck公众号</div>
      <div class="msg">关注公众号可随时查看订单信息</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import mitts from "@/bus";
import API from "@/axios";
import { Toast } from "vant";
import wx from 'jweixin-js';

// const router = useRouter();
const route = useRoute()

const allprice = ref("")

const order_no = ref("")

const userToken = ref("")

const linkurl = ref("")

const mini_username = ref("")

// function toOrder() {
//   router.push({
//     path: linkurl
//   });
// }

function flowCkick() {
  window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5MTg1NTc4MQ==#wechat_redirect"
}

function paySuccess() {
  API({
    url: "/api/paySuccess",
    method: "post",
    data: {
      userToken: userToken.value,
      order_no: order_no.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      allprice.value = res.data.data.pay_money
      linkurl.value = res.data.data.mini_path
      mini_username.value = res.data.data.mini_username
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function getWxConfig() {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    if (res.data.status == 200) {

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: [], 	openTagList: ['wx-open-launch-weapp']
      });


      wx.error(function (res) {
        console.log('wx.error', res)
        Toast.fail({
          message: '微信JSSDK信息验证失败！'
        });
      });
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

onMounted(() => {
  order_no.value = route.query.order_no
  userToken.value = window.localStorage.getItem('userToken');
  paySuccess()
  getWxConfig()

  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 4000)
});
</script>

<style lang="less" scoped>
.payDonePage {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  background: #fff;

  .contant {
    width: 100%;
    height: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    padding-bottom: 102px;
    box-sizing: border-box;
  }

  .icon {
    color: #68bf7b;
    margin-bottom: 20px;
  }

  .text {
    font-size: 28px;
    line-height: 28px;
    color: #19AD17;
    margin-bottom: 106px;
  }

  .price {
    display: flex;
    align-items: center;
    height: 112px;
    line-height: 112px;
    font-size: 80px;
    font-weight: 400;
    color: #050E20;
    margin-bottom: 16px;

    img {
      display: block;
      width: 48px;
      height: 48px;
      margin-right: 18px;
    }
  }

  .tip {
    font-size: 28px;
    font-weight: 400;
    color: #9599A0;
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    color: #050E20;
    margin-bottom: 18px;
  }

  .bottom {
    margin-bottom: 80px;
  }

  .btn {
    width: 100%;
    margin-bottom: 64px;
    display: flex;
    justify-content: center;

    button {
      width: 50%;
      height: 68px;
      line-height: 68px;
      border-radius: 8px;
      border: 1px solid #476FFB;
      font-size: 28px;
      font-weight: 400;
      color: #476FFB;
      background: none;
    }
  }

  .msg {
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    color: #9599A0;
  }

  .backhome {
    text-decoration: underline;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #9599A0;
    margin-bottom: 12px;
  }
}
</style>
